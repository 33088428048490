import React from "react";
import { IconTypes } from "ui/primitives/IconTypes";
import { getThemeColor, Color } from "ui/primitives";

export default ({
                  width = 24,
                  height = 24,
                  primaryColor = Color.text_default,
                  variant
                }: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_399_6636)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 10.5C8.75 10.0858 9.08579 9.75 9.5 9.75H14.5C14.9142 9.75 15.25 10.0858 15.25 10.5C15.25 10.9142 14.9142 11.25 14.5 11.25H13.8176C13.8877 11.3916 13.9458 11.5402 13.9904 11.6944H14.5C14.9142 11.6944 15.25 12.0302 15.25 12.4444C15.25 12.8587 14.9142 13.1944 14.5 13.1944H13.9904C13.6656 14.3176 12.6295 15.1389 11.4017 15.1389H11.1409L12.7594 17.0092C13.0305 17.3224 12.9963 17.7961 12.6831 18.0671C12.3699 18.3382 11.8962 18.304 11.6252 17.9908L9.18105 15.1665C8.66349 14.5684 9.08833 13.6389 9.87925 13.6389H11.4017C11.7773 13.6389 12.1124 13.4655 12.3314 13.1944H9.5C9.08579 13.1944 8.75 12.8587 8.75 12.4444C8.75 12.0302 9.08579 11.6944 9.5 11.6944L12.3314 11.6944C12.1124 11.4234 11.7773 11.25 11.4017 11.25H9.5C9.08579 11.25 8.75 10.9142 8.75 10.5Z"
          fill={primaryColorValue}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1656 0.43393C11.4782 0.705647 11.5114 1.17936 11.2397 1.492L9.5611 3.42338C10.3263 3.25546 11.1513 3.1528 12.0486 3.1528C17.7337 3.1528 22.3472 7.76636 22.3472 13.4514C22.3472 19.1364 17.7337 23.75 12.0486 23.75C6.36356 23.75 1.75 19.1364 1.75 13.4514C1.75 11.3303 2.38919 9.37303 3.47892 7.72816C3.70769 7.38285 4.17307 7.28838 4.51838 7.51714C4.86369 7.74591 4.95816 8.21129 4.7294 8.5566C3.79482 9.96728 3.25 11.6384 3.25 13.4514C3.25 18.308 7.19199 22.25 12.0486 22.25C16.9052 22.25 20.8472 18.308 20.8472 13.4514C20.8472 8.59479 16.9052 4.6528 12.0486 4.6528C11.0396 4.6528 10.1342 4.80164 9.30039 5.03342L11.6513 6.75419C11.9855 6.99884 12.0582 7.46813 11.8135 7.80237C11.5689 8.13661 11.0996 8.20924 10.7653 7.96459L7.06048 5.25279C6.89118 5.12887 6.78153 4.9397 6.75814 4.73121C6.73475 4.52271 6.79975 4.31395 6.93738 4.1556L10.1075 0.508024C10.3792 0.195386 10.853 0.162213 11.1656 0.43393Z"
          fill={primaryColorValue}
        />
      </g>
      <defs>
        <clipPath id="clip0_399_6636">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
