import cn from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import ChallanSVG from 'public/img/header/challan.svg';
import FastagSVG from 'public/img/header/fastag.svg';
import InsuranceSVG from 'public/img/header/insurance.svg';
import ParkingSVG from 'public/img/header/parking.svg';
// import CartradeSVG from 'public/img/header/cartrade.svg';
import ArrowRightSVG from 'public/img/header/arrow-right.svg';
import ParkingPic from 'public/img/header/car-parking.png';
import ChargingPic from 'public/img/header/ev-charging.png';
// import AccordionRightSVG from 'src/common/icons/arrow-right-blue.svg';

import { loginPopupUpdate } from 'auth/store/action';
import { getOriginByEnv } from 'core-lib/config/config';
import {
  getFullName,
  getUserProfilePic,
  isObject,
} from 'core-lib/utils/helpers';
import ChevronDown from 'src/common/icons/accordian-down.svg';
import ParkplusSVG from 'src/common/icons/parkplus-website-logo.svg';
import { redirectToPWA } from 'src/utils/isAuthAndRedirect';
import { Button } from 'ui';
import CarDefault from 'ui/components/newIcons/CarDefault';
import ChevronRight from 'ui/components/newIcons/ChevronRight';
import FuelPump from 'ui/components/newIcons/FuelPump';
import MoneyReceive from 'ui/components/newIcons/MoneyReceive';
import HotSVG from '../../../common/icons/hot.svg';
import s from './headerv1.module.scss';

export const LINK_TYPE = {
  default: 'default',
  menu: 'menu',
  rounded: 'rounded',
  rectangle: 'rectangle',
  label: 'label',
};

export const SOURCE_TYPE = {
  internal: 'internal',
  external: 'external',
  hash: 'hash',
};

export const HEADER_LINK_LIST = {
  fastag: {
    label: 'FASTag',
    key: 'fastag',
    type: LINK_TYPE.default,
    sourceType: SOURCE_TYPE.internal,
    route: ['/fastag/', '/f/[dynamic_url]', '/services/fastag'],
    href: '/fastag',
    isSpecial: true,
    logo: <HotSVG />,
  },
  company: {
    label: 'Company',
    key: 'company',
    type: LINK_TYPE.menu,
    route: [],
    sourceType: SOURCE_TYPE.internal,
    options: {
      aboutUs: {
        label: 'About Us',
        key: 'About Us',
        title:
          "We started with a vision to solve the problem of parking in India, however, we went all hands on deck and decided to build everything a car owner needs in today's time",
        type: LINK_TYPE.menu,
        sourceType: SOURCE_TYPE.internal,
        href: '/about-us',
        icon: (
          <ChevronRight
            primaryColor="interaction_button"
            height={20}
            width={20}
          />
        ),
      },
      newsroom: {
        label: 'Newsroom',
        key: 'Newsroom',
        title:
          'Get a glimpse of when we made it to the news. Find media mentions and company news here. Stay updated about everything that Park+ is doing.',
        type: LINK_TYPE.menu,
        sourceType: SOURCE_TYPE.internal,
        href: '/newsroom',
        icon: (
          <ChevronRight
            primaryColor="interaction_button"
            height={20}
            width={20}
          />
        ),
      },
      caseStudies: {
        label: 'Case Studies',
        key: 'Case Studies',
        title:
          'We believe in creating innovative and customised solutions for establishments. Read more about how we solved the problem of parking for our clients',
        type: LINK_TYPE.menu,
        sourceType: SOURCE_TYPE.internal,
        href: '/case-study',
        icon: (
          <ChevronRight
            primaryColor="interaction_button"
            height={20}
            width={20}
          />
        ),
      },
    },
  },

  products: {
    label: 'Products',
    key: 'products',
    route: [],
    type: LINK_TYPE.menu,
    sourceType: SOURCE_TYPE.internal,

    options: {
      business: {
        label: 'FOR BUSINESSES',
        key: 'businesses',
        type: LINK_TYPE.label,
      },
      parkplusForBusiness: {
        label: 'Park+ for Businesses',
        key: 'Park+ for Businesses',
        type: LINK_TYPE.menu,
        sourceType: SOURCE_TYPE.internal,
        href: `/business`,
        title:
          'Our technology has transformed the tricky traffic movement in parking lots for various business establishments',
        image: ParkingPic,
        bkColor: '#FFF7D6',
        icon: <ArrowRightSVG />,
      },
      evChargingSolution: {
        label: 'EV Charging Solution',
        key: 'EV Charging Solution',
        type: LINK_TYPE.menu,
        sourceType: SOURCE_TYPE.internal,
        href: '/ev',
        title:
          'Proactively formulate global niche markets whereas maintainable innovation. Progressively.',
        image: ChargingPic,
        bkColor: '#E3F9E7',
        icon: <ArrowRightSVG />,
      },
    },
    options2: {
      forCustomers: {
        label: 'FOR CUSTOMERS',
        key: 'individuals',
        type: LINK_TYPE.label,
      },
      parking: {
        label: 'Parking',
        key: 'parking',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.internal,
        href: `/parking`,
        icon: <ParkingSVG />,
        title: 'Find and book parking near you',
      },
      fastag: {
        label: 'FASTag',
        key: 'FASTag',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.internal,
        href: '/fastag',
        icon: <FastagSVG />,
        title: 'Buy, activate, and recharge FASTag',
      },
      // carWash: {
      //   label: 'Car Wash',
      //   key: 'carWash',
      //   type: LINK_TYPE.default,
      //   sourceType: SOURCE_TYPE.internal,
      //   href: '/car-wash',
      //   icon: <CarwashSVG />,
      //   title: 'Professional doorstep car cleaning',
      // },
      challan: {
        label: 'Challan',
        key: 'challan',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.external,
        href: '/e-challan',
        icon: <ChallanSVG />,
        title: 'Check real-time challans',
      },
      insurance: {
        label: 'Insurance',
        key: 'Insurance',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.external,
        href: `/car-insurance`,
        icon: <InsuranceSVG />,
        title: 'Get zero-commission Car Insurance',
      },
      bbps: {
        label: 'Bill Payments',
        key: 'bbps',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.external,
        href: `/app/services/bill-payment/bill-categories`,
        icon: <MoneyReceive />,
        title: 'Get Cashback on Bill Payments',
      },
      fuel: {
        label: 'Buy Fuel',
        key: 'fuel',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.external,
        href: `/app/shop/parkplus-fuel`,
        icon: <FuelPump />,
        title: 'Get 2% Cashback on Fuel Payments',
      },
      valetService: {
        label: 'Valet Services',
        key: 'Valet Services',
        type: LINK_TYPE.default,
        sourceType: SOURCE_TYPE.internal,
        href: '/business/valet-service',
        icon: <CarDefault />,
        title: 'Trusted by Top Brands',
      },
      // carTrade: {
      //   label: 'Car Trade',
      //   key: 'Car Trade',
      //   type: LINK_TYPE.default,
      //   sourceType: SOURCE_TYPE.internal,
      //   href: '#coming-soon',
      //   icon: <CarDefault/>,
      //   title: 'Best selling price for your car',
      // },
    },
  },

  careers: {
    label: 'Careers',
    key: 'careers',
    type: LINK_TYPE.default,
    sourceType: SOURCE_TYPE.internal,
    route: '',
    href: '/careers/',
  },
  getInTouch: {
    label: 'Login',
    key: 'profile',
    type: LINK_TYPE.rounded,
    sourceType: SOURCE_TYPE.internal,
    route: [],
    href: `#contact-us`,
  },
  getInTouch: {
    label: 'Get in touch 👋 ',
    key: 'getintouch',
    type: LINK_TYPE.rounded,
    sourceType: SOURCE_TYPE.internal,
    route: [],
    href: `#contact-us`,
  },
};

function HeaderV1({
  isAuthenticated,
  profile,
  activeLink,
  loginPopupUpdate,
  showProfile,
  showContact,
  isExpanded,
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  function renderProfileLink() {
    if (isAuthenticated) {
      return (
        <div className={[s.item, s.item_profile].join(' ')}>
          <button onClick={() => redirectToPWA('/profile')} type="button">
            <span
              className={s.img}
              style={{
                backgroundImage: `url('${getUserProfilePic(profile)}')`,
              }}
            />
            {isObject(profile) && (
              <span className={s.label}>
                {getFullName(profile.first_name, profile.last_name)}
              </span>
            )}
          </button>
        </div>
      );
    }
    return (
      <Button
        label="Login"
        type="button"
        customStyle={{
          zIndex: 6,
          margin: '0 0 0 30px',
        }}
        minWidth="150px"
        variant="ButtonTertiary"
        size="Big"
        onClick={() => {
          loginPopupUpdate({ show: true });
        }}
      />
    );
  }

  return (
    <header className={s.root}>
      <div className={cn(s.box, 'cmn_container')}>
        <div className={s.logo}>
          <Link href="/" prefetch={false} aria-label="home">
            <ParkplusSVG />
          </Link>
        </div>
        <div className={s.link_n_options}>
          {Object.keys(HEADER_LINK_LIST).map((el) => {
            const item = HEADER_LINK_LIST[el];
            let render = true;
            if (!showContact && item.key === 'getintouch') render = false;
            if (render) {
              const elems = (
                <>
                  <span className={s.label}>
                    {item.label}
                    {item.type === LINK_TYPE.menu && (
                      <span className={s.icon}>
                        <ChevronDown />
                      </span>
                    )}
                  </span>
                  {item.type === LINK_TYPE.menu && isObject(item.options) && (
                    <div className={[s.menu_option, 'cmn_animation'].join(' ')}>
                      <div
                        className={cn(
                          el === 'company' && s.company_option,
                          s.right_option
                        )}
                      >
                        {Object.keys(item.options).map((optionEl, idx) => {
                          const menuItem = item.options[optionEl];
                          let setHref = menuItem.href;
                          if (menuItem.sourceType === SOURCE_TYPE.external) {
                            setHref = `${getOriginByEnv()}${menuItem.href}`;
                          }
                          return (
                            <div
                              className={s.item}
                              key={`${menuItem.key}_${idx}_menu_item`}
                              style={{ backgroundColor: menuItem.bkColor }}
                            >
                              {menuItem.sourceType === SOURCE_TYPE.internal && (
                                <div className={s.item_content}>
                                  <Link href={setHref} prefetch={false}>
                                    {menuItem.label}
                                    <p className={s.item_title}>
                                      {menuItem.title}
                                    </p>
                                    {menuItem.image && (
                                      <Image
                                        src={menuItem.image}
                                        alt={menuItem.label}
                                      />
                                    )}
                                  </Link>
                                </div>
                              )}
                              {menuItem.sourceType === SOURCE_TYPE.external && (
                                <a href={setHref}>{menuItem.label}</a>
                              )}
                              {menuItem.sourceType === SOURCE_TYPE.hash && (
                                <div
                                  onClick={() => {
                                    router.push(menuItem.href);
                                  }}
                                  className={s.sublinks}
                                  aria-hidden
                                >
                                  {menuItem.label}
                                </div>
                              )}
                              {menuItem.type === LINK_TYPE.label && (
                                <div className={s.item_label}>
                                  {menuItem.label}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>

                      {item.options2 && <div className={s.divider} />}

                      {item.options2 && (
                        <div className={s.left_option}>
                          {Object.keys(item.options2).map((optionEl) => {
                            const menuItem = item.options2[optionEl];
                            let setHref = menuItem.href;
                            if (menuItem.sourceType === SOURCE_TYPE.external) {
                              setHref = `${getOriginByEnv()}${menuItem.href}`;
                            }
                            return (
                              <div
                                className={s.item}
                                key={`${menuItem.key}_options_2`}
                              >
                                {[
                                  SOURCE_TYPE.internal,
                                  SOURCE_TYPE.external,
                                ].includes(menuItem.sourceType) && (
                                  <Link href={setHref} prefetch={false}>
                                    <div className={s.item_icon}>
                                      {menuItem.icon}
                                    </div>
                                    <div className={s.item_content}>
                                      <Link href={setHref} prefetch={false}>
                                        {menuItem.label}
                                      </Link>
                                      <p className={s.item_title}>
                                        {menuItem.title}
                                      </p>
                                    </div>
                                  </Link>
                                )}

                                {menuItem.type === LINK_TYPE.label && (
                                  <div className={s.item_label}>
                                    {menuItem.label}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
              let setHref = item.href;
              if (item.sourceType === SOURCE_TYPE.external) {
                setHref = `${getOriginByEnv()}${item.href}`;
              }
              return (
                <div
                  key={`${item.key}_@^$`}
                  className={[
                    s.item,
                    item.type === LINK_TYPE.default && s.item_link,
                    item.type === LINK_TYPE.rounded && s.item_btn_rounded,
                    item.type === LINK_TYPE.rectangle && s.item_btn_rectangle,
                    item.type === LINK_TYPE.menu && s.item_menu,
                    item.route?.indexOf(activeLink) !== -1 &&
                      s.item_active_link,
                  ].join(' ')}
                >
                  <div
                    className={s.link_item}
                    onClick={() => {
                      if (item.type !== LINK_TYPE.rounded) {
                        if (item.sourceType === SOURCE_TYPE.internal) {
                          if (setHref) {
                            router.push(setHref);
                          }
                        } else {
                          global.window.location.href = setHref;
                        }
                      } else {
                        // dispatch(showContactForm({ show: true }));
                        // router.push(setHref);
                        global.location.hash = '';
                        global.location.hash = setHref;
                      }
                    }}
                    aria-hidden
                  >
                    <span className={s.hot_logo}>
                      {item.isSpecial && item.logo}
                    </span>
                    {elems}
                  </div>
                </div>
              );
            }
            return null;
          })}
          {showProfile && renderProfileLink()}
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
  };
}

export default connect(mapStateToProps, { loginPopupUpdate })(HeaderV1);

HeaderV1.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  profile: PropTypes.objectOf(PropTypes.any),
  activeLink: PropTypes.string,
  loginPopupUpdate: PropTypes.func.isRequired,
  showProfile: PropTypes.bool,
  showContact: PropTypes.bool,
  isExpanded: PropTypes.bool,
};

HeaderV1.defaultProps = {
  profile: null,
  activeLink: null,
  showProfile: true,
  showContact: false,
  isExpanded: true,
};
