import { Color } from "ui/primitives";
import { IconTypes } from "ui/primitives/IconTypes";
export default ({
  width = 24,
  height = 24,
  primaryColor = Color.text_default,
  variant,
}: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9L23 9"
        stroke={primaryColorValue}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 12C16.75 11.5858 17.0858 11.25 17.5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H17.5C17.0858 12.75 16.75 12.4142 16.75 12Z"
        fill={primaryColorValue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H6.5C6.91421 11.25 7.25 11.5858 7.25 12C7.25 12.4142 6.91421 12.75 6.5 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z"
        fill={primaryColorValue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56617 3.4338C5.7875 3.31337 6.03546 3.25027 6.28743 3.25024H17.4625C17.7145 3.25027 17.9625 3.31337 18.1838 3.4338C18.4047 3.554 18.5921 3.72748 18.7289 3.93849M18.7297 3.93971L21.8789 8.78657C21.9579 8.90821 22 9.05015 22 9.1952V14.75C22 15.1643 21.6642 15.5 21.25 15.5H2.5C2.08579 15.5 1.75 15.1643 1.75 14.75V9.1952C1.75 9.05015 1.79206 8.90821 1.87109 8.78657L5.0203 3.93971C5.02045 3.93948 5.02015 3.93994 5.0203 3.93971C5.15714 3.72846 5.34508 3.5541 5.56617 3.4338M6.28757 4.75024C6.286 4.75024 6.28446 4.75064 6.28309 4.75138C6.28171 4.75213 6.28055 4.75321 6.2797 4.75453L3.25 9.41746V14H20.5V9.41745L17.4711 4.75575L17.4703 4.75453C17.4695 4.75322 17.4683 4.75213 17.4669 4.75138C17.4655 4.75064 17.464 4.75024 17.4624 4.75024H6.28757Z"
        fill={primaryColorValue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 14C17.9142 14 18.25 14.3358 18.25 14.75L18.25 17.75L20.5 17.75V14.75C20.5 14.3358 20.8358 14 21.25 14C21.6642 14 22 14.3358 22 14.75V17.75C22 18.1478 21.842 18.5294 21.5607 18.8107C21.2794 19.092 20.8978 19.25 20.5 19.25H18.25C17.8522 19.25 17.4706 19.092 17.1893 18.8107C16.908 18.5294 16.75 18.1478 16.75 17.75V14.75C16.75 14.3358 17.0858 14 17.5 14Z"
        fill={primaryColorValue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 14C2.91421 14 3.25 14.3358 3.25 14.75V17.75L5.5 17.75V14.75C5.5 14.3358 5.83579 14 6.25 14C6.66421 14 7 14.3358 7 14.75V17.75C7 18.1478 6.84196 18.5294 6.56066 18.8107C6.27935 19.092 5.89782 19.25 5.5 19.25H3.25C2.85218 19.25 2.47065 19.092 2.18934 18.8107C1.90803 18.5294 1.75 18.1478 1.75 17.75V14.75C1.75 14.3358 2.08579 14 2.5 14Z"
        fill={primaryColorValue}
      />
    </svg>
  );
};
