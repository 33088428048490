import router from 'next/router';
import toast from 'react-hot-toast';
import qs from 'qs';
import { DictType } from 'core-lib/types/Helper';
import { isStringValid } from 'core-lib/utils/utils';
import { PWA_URL } from 'core-lib/data/common';

export function redirectToPWA(route: string, params: DictType = {}) {
  if (!isStringValid(route)) toast.error('invalid route');

  const queryObj: DictType = {
    ...params,
  };

  router.push({
    pathname: `${PWA_URL}${route}`,
    query: qs.stringify(queryObj),
  });
}
